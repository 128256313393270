<div class="container">
  <div class="row justify-content-center row-first">
    <div class="col-lg-6 columns col-12">
      <div class="card card-body shadow">
        <div>
          <h1 class="text-center h1">
            <b [innerHtml]="'slide-1' | translatelocal"></b>
          </h1>
          <br />

          <form [formGroup]="signupForm">
            <div class="form-group">
              <div class="form-control input-group">
                <input
                  type="text"
                  class="form-control"
                  formControlName="hotelName"
                  autocomplete="off"
                  placeholder="{{ 'hotelname' | translatelocal }}"
                  [(ngModel)]="query"
                  (keyup)="search(query)"
                />
                <div class="input-group-append">
                  <button class="btn btn-primary" (click)="suggestions = []">
                    <fa-icon
                      [icon]="suggestions.length > 0 ? close : searchIcon"
                      *ngIf="!loading; else spinner"
                    ></fa-icon>
                    <ng-template #spinner>
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </ng-template>
                  </button>
                </div>
              </div>
              <ul
                class="list-group suggestions shadow"
                *ngIf="suggestions.length > 0"
              >
                <li
                  class="list-group-item"
                  *ngFor="let suggest of suggestions"
                  (click)="
                    setHotel(
                      suggest.name,
                      suggest.destinationId,
                      suggest.longitude,
                      suggest.latitude
                    )
                  "
                >
                  {{ suggest.name }}
                </li>
              </ul>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-6">
                  <div
                    class="form-control"
                    [class]="{
                      'shadow-danger':
                        signupForm.controls.name.dirty &&
                        signupForm.controls.name.invalid
                    }"
                  >
                    <input
                      type="text"
                      class="form-control"
                      name="sign-up-password"
                      formControlName="name"
                      placeholder="{{ 'name' | translatelocal }}"
                    />
                  </div>
                </div>
                <div class="col-6">
                  <div
                    class="form-control"
                    [class]="{
                      'shadow-danger':
                        signupForm.controls.surname.dirty &&
                        signupForm.controls.surname.invalid
                    }"
                  >
                    <input
                      type="text"
                      class="form-control"
                      name="sign-up-password"
                      formControlName="surname"
                      placeholder="{{ 'surname' | translatelocal }}"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div
                class="form-control"
                [class]="{
                  'shadow-danger':
                    signupForm.controls.mail.dirty &&
                    signupForm.controls.mail.invalid
                }"
              >
                <input
                  type="email"
                  class="form-control"
                  placeholder="{{ 'email' | translatelocal }}"
                  formControlName="mail"
                />
              </div>
              <div
                class="row"
                *ngIf="
                  signupForm.controls.mail?.dirty &&
                  signupForm.controls.mail.invalid
                "
              >
                <div class="col-12">
                  <div class="toast">
                    {{ "invalidmail" | translatelocal }}
                  </div>
                </div>
              </div>
            </div>

            <button
              class="btn btn-primary w-100"
              type="submit"
              [disabled]="!signupForm.valid"
              (click)="save()"
            >
              {{ "Create Account" | translatelocal }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="right-svg"></div>
    <img
      src="../../../../assets/images/resort.png"
      alt="Guest Service"
      class="mockup"
    />
  </div>
</div>
