import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {

  userLang = localStorage.getItem("userLang") || navigator.language.substring(0, 2)

  transform(value: string): string {
    
    try {
      value = JSON.parse(value)[`${this.userLang}`]
    } catch {
      return value
    }

    return value
    
  }

}
