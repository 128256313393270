import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SseService {
  refreshListenerEvent: EventSource;

  constructor() {}

  getHotelPhoto(destination_id: string): Observable<string> {
    const url = `https://icibot.net/public_streams/guest_service_signup_hotel_images?destination_id=${destination_id}`;
    this.refreshListenerEvent = new EventSource(url);

    return new Observable((observer) => {
      this.refreshListenerEvent.onmessage = (event) => {
        observer.next(event.data);
      };

      this.refreshListenerEvent.onerror = (error) => {
        observer.error(error);
        this.refreshListenerEvent.close();
      };

      return () => {
        this.refreshListenerEvent.close();
      };
    });
  }
}
