export class AppHotelModel {
    email: string;
    password: string;
    phone: string;
    id: number;
    facebook_id: number;
    name: string;
    contact_name: string;
    contact_phone: string;
    contact_email: string;
    call_center: string;
    master_color: string;
    activated: boolean;
    address: string;
    post_code: string;
    city: string;
    country: string;
    currency_code: string;
    languages: string;
    place_id: string;
    image_url: string;
    photos: string;
    rating: number;
    web_site_url: string;
    lat_lng: string;
    online_reservation_url: string;
    direct_reservation: boolean;
    reservation_mail_address: string;
    group_hotels: string;
    google_analytics_link: string;
    yandex_metrica_link: string;
    bing_link: string;
    survey_url: string;
    hotel_information_tr: string;
    hotel_information_en: string;
    hotel_information_ru: string;
    hotel_information_de: string;
    hotel_information_fr: string;
    hotel_information_ar: string;
    hotel_information_ir: string;
    hotel_information_es: string;
    sub_color: string;
    kvkk_link: string;
    privacy_policy_link: string;
    sp_doctor: string;
    sp_reception: string;
    sp_room_service: string;
    sp_customer_relation: string;
    sp_technical_service: string;
    sp_management: string;
    plan_image_url: string;
    sm_youtube_url: string;
    sm_instagram_url: string;
    sm_facebook_url: string;
    sm_twitter_url: string;
    sm_vkontakte_url: string;
    created_at: Date;
    updated_at: Date;
    mobile_version: number;
    mobile_version_publish_date: Date;
    mobile_publish_by: string;
    hotel_chain: string;
    online_check_in: boolean;
    online_check_in_image: boolean;
    online_check_in_signature: boolean;
    check_in_post_message: string;
    welcome_message: string;
    survey_title: string;
    survey_description: string;
    delivery_time_of_day: string;
    notification_point: number;
    survey_point: number;
    sub_domain_name: string;
    bars: MenuSectionModel;
    cafes: MenuSectionModel;
    golf_club: MenuSectionModel;
    kids_club: MenuSectionModel;
    rooms: MenuSectionModel;
    massage: MenuSectionModel;
    meeting_rooms: MenuSectionModel;
    other_facilities: MenuSectionModel;
    pools: MenuSectionModel;
    restaurants: MenuSectionModel;
    spa: MenuSectionModel;
    sport: MenuSectionModel;
    amenities: MenuSectionModel;
    housekeeping: MenuSectionModel;
    issues: MenuSectionModel;
    complaint: MenuSectionModel;
    other_services: MenuSectionModel;
    room_services: MenuSectionModel;
    activity: MenuSectionModel;
    daily_animations: MenuSectionModel;
    shows: MenuSectionModel;
    po_is: MenuSectionModel;
    tours: MenuSectionModel;
    opportunities: MenuSectionModel;
    news: MenuSectionModel;
    banner: MenuSectionModel;
    loyality_management: MenuSectionModel;
    room_guide: MenuSectionModel;
    malls: MenuSectionModel;
    upsell: MenuSectionModel;
    front_office: MenuSectionModel;
    mice: MenuSectionModel;
    wedding: MenuSectionModel;
    avm: MenuSectionModel;
    life: MenuSectionModel;
    cinema: MenuSectionModel;
    events: MenuSectionModel;
    other_1: MenuSectionModel;
    other_2: MenuSectionModel;
    other_3: MenuSectionModel;
    other_4: MenuSectionModel;
    other_5: MenuSectionModel;
    other_6: MenuSectionModel;
    other_7: MenuSectionModel;
    other_8: MenuSectionModel;
    video: MenuSectionModel;
    services: MenuSectionModel;
    slider_images: string[];
    logo: string[];
    dark_logo: string[];
    light_logo: string[];
    title_and_descriptions: TitleAndDescription[];
    one_signal_app_id: string;
    one_signal_app_key: string;
    one_signal_user_key: string;
    app_store_link: string;
    google_play_link: string;
    firebase_link: string;
    open_for_year: boolean;
    open_date: Date;
    close_date: Date;
    entry_date: Date;
    release_date: Date;
    baby_age_range: string;
    child_age_range: string;
    old_age_range: string;
    close_request: boolean;
    call_for_requests: boolean;
    room_number_validation_code: string;
    claim_tracking_manager: boolean;
    hotel_type: string;
    mobile_payment: boolean;
    title: string;
    description: string;
    wifi_name: string;
    wifi_password: string;
    whatsapp_no: string;
    viber_no: string;
    telegram_no: string;
    messenger_address: string;
    google_map_address: string;
    show_welcome: boolean;
    show_chat: boolean;
  product_type: string;

    constructor() {
        this.id = 0;
        this.facebook_id = 0;
        this.name = '';
        this.contact_name = '';
        this.contact_phone = '';
        this.contact_email = '';
        this.call_center = '';
        this.master_color = '';
        this.activated = false;
        this.address = '';
        this.post_code = '';
        this.city = '';
        this.country = '';
        this.currency_code = '';
        this.languages = '';
        this.place_id = '';
        this.image_url = '';
        this.photos = '';
        this.rating = 0;
        this.web_site_url = '';
        this.lat_lng = '';
        this.online_reservation_url = '';
        this.direct_reservation = false;
        this.reservation_mail_address = '';
        this.group_hotels = '';
        this.google_analytics_link = '';
        this.yandex_metrica_link = '';
        this.bing_link = '';
        this.survey_url = '';
        this.hotel_information_tr = '';
        this.hotel_information_en = '';
        this.hotel_information_ru = '';
        this.hotel_information_de = '';
        this.hotel_information_fr = '';
        this.hotel_information_ar = '';
        this.hotel_information_ir = '';
        this.hotel_information_es = '';
        this.sub_color = '';
        this.kvkk_link = '';
        this.privacy_policy_link = '';
        this.sp_doctor = '';
        this.sp_reception = '';
        this.sp_room_service = '';
        this.sp_customer_relation = '';
        this.sp_technical_service = '';
        this.sp_management = '';
        this.plan_image_url = '';
        this.sm_youtube_url = '';
        this.sm_instagram_url = '';
        this.sm_facebook_url = '';
        this.sm_twitter_url = '';
        this.sm_vkontakte_url = '';
        this.created_at = new Date();
        this.updated_at = new Date();
        this.mobile_version = 0;
        this.mobile_version_publish_date = new Date();
        this.mobile_publish_by = '';
        this.hotel_chain = '';
        this.online_check_in = false;
        this.online_check_in_image = false;
        online_check_in_signature: false;
        this.check_in_post_message = '';
        this.welcome_message = '';
        this.survey_title = '';
        this.survey_description = '';
        this.delivery_time_of_day = '';
        this.notification_point = 0;
        this.survey_point = 0;
        this.sub_domain_name = '';
        this.bars = new MenuSectionModel();
        this.cafes = new MenuSectionModel();
        this.golf_club = new MenuSectionModel();
        this.kids_club = new MenuSectionModel();
        this.rooms = new MenuSectionModel();
        this.massage = new MenuSectionModel();
        this.meeting_rooms = new MenuSectionModel();
        this.other_facilities = new MenuSectionModel();
        this.pools = new MenuSectionModel();
        this.restaurants = new MenuSectionModel();
        this.spa = new MenuSectionModel();
        this.sport = new MenuSectionModel();
        this.amenities = new MenuSectionModel();
        this.housekeeping = new MenuSectionModel();
        this.issues = new MenuSectionModel();
        this.complaint = new MenuSectionModel();
        this.other_services = new MenuSectionModel();
        this.room_services = new MenuSectionModel();
        this.activity = new MenuSectionModel();
        this.daily_animations = new MenuSectionModel();
        this.shows = new MenuSectionModel();
        this.po_is = new MenuSectionModel();
        this.tours = new MenuSectionModel();
        this.opportunities = new MenuSectionModel();
        this.news = new MenuSectionModel();
        this.banner = new MenuSectionModel();
        this.loyality_management = new MenuSectionModel();
        this.room_guide = new MenuSectionModel();
        this.malls = new MenuSectionModel();
        this.upsell = new MenuSectionModel();
        this.front_office = new MenuSectionModel();
        this.mice = new MenuSectionModel();
        this.wedding = new MenuSectionModel();
        this.avm = new MenuSectionModel();
        this.life = new MenuSectionModel();
        this.cinema = new MenuSectionModel();
        this.events = new MenuSectionModel();
        this.other_1 = new MenuSectionModel();
        this.other_2 = new MenuSectionModel();
        this.other_3 = new MenuSectionModel();
        this.other_4 = new MenuSectionModel();
        this.other_5 = new MenuSectionModel();
        this.other_6 = new MenuSectionModel();
        this.other_7 = new MenuSectionModel();
        this.other_8 = new MenuSectionModel();
        this.video = new MenuSectionModel();
        this.services = new MenuSectionModel();
        this.slider_images = [];
        this.logo = [];
        this.dark_logo = [];
        this.light_logo = [];
        this.title_and_descriptions = [new TitleAndDescription()];
        this.one_signal_app_id = '';
        this.one_signal_app_key = '';
        this.one_signal_user_key = '';
        this.app_store_link = '';
        this.google_play_link = '';
        this.firebase_link = '';
        this.open_for_year = false;
        this.open_date = new Date();
        this.close_date = new Date();
        this.entry_date = new Date();
        this.release_date = new Date();
        this.baby_age_range = '';
        this.child_age_range = '';
        this.old_age_range = '';
        this.close_request = false;
        this.call_for_requests = false;
        this.room_number_validation_code = '';
        this.claim_tracking_manager = false;
        this.hotel_type = '';
        this.mobile_payment = false;
        this.title = '';
        this.description = '';
        this.wifi_name = '';
        this.wifi_password = '';
        this.whatsapp_no = '';
        this.viber_no = '';
        this.telegram_no = '';
        this.messenger_address = '';
        this.google_map_address = '';
        this.show_welcome = false;
        this.show_chat = false;
    }
}

export class MenuSectionModel {
    id: number;
    hotel_id: number;
    menu_area_id: number;
    catalogue_active: boolean;
    timetable_and_reservation_active: boolean;
    title: string;
    show: boolean;
    menu_categories: MenuCategory[] | null;
    request: boolean;

    constructor() {
        this.id = 0;
        this.hotel_id = 0;
        this.menu_area_id = 0;
        this.catalogue_active = false;
        this.timetable_and_reservation_active = false;
        this.title = '';
        this.show = false;
        this.menu_categories = [];
        this.request = false;
    }
}

export class MenuCategory {
    id: number;
    menu_section_id: number;
    title: string;
    hotel_id: number;
    menu_item_id: number;
    menu_items: MenuItem[] | null;
    images: Image[] | null;

    constructor() {
        this.id = 0;
        this.menu_section_id = 0;
        this.title = '';
        this.hotel_id = 0;
        this.menu_item_id = 0;
        this.menu_items = [];
        this.images = [];
    }
}

export class Image {
    file_name: string;
    url: string;
    key: string;
    last_modified: Date;
    size_KB: number;
}

export class MenuItem {
    id: number;
    menu_section_id: number;
    menu_category_id: number;
    hotel_id: number;
    name: string;
    description: string;
    image_url: string;
    email: string;
    phone: string;
    youtube: string;
    web_site_title: string;
    web_site_url: string;
    virtual_tour_url: string;
    dress_code: string;
    location_description: string;
    activate_map_location: boolean;
    map_location: string;
    map_location_point_icon: string;
    show_title_and_description: boolean;
    show_bullet_list: boolean;
    show_dress_code: boolean;
    show_location_description: boolean;
    show_email: boolean;
    show_phone_number: boolean;
    show_youtube: boolean;
    show_web_site: boolean;
    show_virtual_tour: boolean;
    booking_system: string;
    external_booking_engine_url: string;
    emails_of_the_staff: string;
    see_more: boolean;
    see_more_menu_section_id: number;
    show_follow: boolean;
    show_booking_date: boolean;
    show_loyalty_point: boolean;
    loyalty_min_point: number;
    loyalty_max_point: number;
    show_survey: boolean;
    survey_title: string;
    survey_description: string;
    priority: number;
    child_count: number;
    adult_count: number;
    baby_count: number;
    url_links: string;
    url_title: string;
    auto_approve: boolean;
    photos: string;
    fix_menu: boolean;
    active: boolean;
    show_on_home_page: boolean;
    location_control_mandatory: boolean;
    room_capacity: number;
    min_accomodation: number;
    bed_count: number;
    number_of_bedroom: number;
    bath_count: number;
    field: number;
    base_price: number;
    menu_item_title_and_descriptions: TitleAndDescription[] | null;
    menu_item_bullet_lists: MenuItemBulletList[] | null;
    menu_item_catalog_groups: MenuItemCatalogGroup[] | null;
    menu_item_books: MenuItemBook[] | null;
    tags: Tag[] | null;
    images: Image[] | null;
    pdf_links: PDFLink[] | null;

    constructor() {
        this.id = 0;
        this.menu_section_id = 0;
        this.menu_category_id = 0;
        this.hotel_id = 0;
        this.name = '';
        this.description = '';
        this.image_url = '';
        this.email = '';
        this.phone = '';
        this.youtube = '';
        this.web_site_title = '';
        this.web_site_url = '';
        this.virtual_tour_url = '';
        this.dress_code = '';
        this.location_description = '';
        this.activate_map_location = false;
        this.map_location = '';
        this.map_location_point_icon = '';
        this.show_title_and_description = false;
        this.show_bullet_list = false;
        this.show_dress_code = false;
        this.show_location_description = false;
        this.show_email = false;
        this.show_phone_number = false;
        this.show_youtube = false;
        this.show_web_site = false;
        this.show_virtual_tour = false;
        this.booking_system = '';
        this.external_booking_engine_url = '';
        this.emails_of_the_staff = '';
        this.see_more = false;
        this.see_more_menu_section_id = 0;
        this.show_follow = false;
        this.show_booking_date = false;
        this.show_loyalty_point = false;
        this.loyalty_min_point = 0;
        this.loyalty_max_point = 0;
        this.show_survey = false;
        this.survey_title = '';
        this.survey_description = '';
        this.priority = 0;
        this.child_count = 0;
        this.adult_count = 0;
        this.baby_count = 0;
        this.url_links = '';
        this.url_title = '';
        this.auto_approve = false;
        this.photos = '';
        this.fix_menu = false;
        this.active = false;
        this.show_on_home_page = false;
        this.location_control_mandatory = false;
        this.room_capacity = 0;
        this.min_accomodation = 0;
        this.bed_count = 0;
        this.number_of_bedroom = 0;
        this.bath_count = 0;
        this.field = 0;
        this.base_price = 0;
        this.menu_item_title_and_descriptions = [];
        this.menu_item_bullet_lists = [];
        this.menu_item_catalog_groups = [];
        this.menu_item_books = [];
        this.tags = [];
        this.images = [];
        this.pdf_links = [];
    }
}

export class MenuItemBook {
    id: number;
    menu_item_id: number;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
    show_advanced_options: boolean;
    bookable_in_advance_days: number;
    minimum_time_in_advance_minute: number;
    minimum_time_in_advance_type: string;
    reservation_unit_custom: boolean;
    reservation_custom_unit_name: string;
    response_for_pending_service: string;
    responce_for_confirmed_service: string;
    call_to_action_text: string;
    comments_text: string;
    menu_item_book_details: MenuItemBookDetail[] | null;
}

export class MenuItemBookDetail {
    id: number;
    menu_item_id: number;
    menu_item_book_id: number;
    start_time: Date;
    end_time: Date;
    name: string;
    bookable: boolean;
    interval: number;
    price: number;
    activate_offer: boolean;
    offer_price: number;
    offer_title: string;
    currency_code: string;
    view_count: number;
    limit: number;
    child_price: number;
    book_detail_res_group: null;
}


export class MenuItemBulletList {
    id: number;
    menu_item_id: number;
    title: string;
}

export class MenuItemCatalogGroup {
    id: number;
    menu_item_id: number;
    name: string;
    menu_item_catalogs: MenuItemCatalog[] | null;
}

export class MenuItemCatalog {
    id: number;
    menu_item_catalog_group_id: number;
    menu_item_id: number;
    priority: number;
    name: string;
    description: string;
    menu_item_catalog_prices: MenuItemCatalogPrice[];
    images: Image[] | null;
}

export class MenuItemCatalogPrice {
    id: number;
    menu_item_catalog_id: number;
    menu_item_id: number;
    price: number;
    size: string;
    activate_offer: boolean;
    offer_price: number;
    offer_title: string;
    currency_code: string;
}

export class TitleAndDescription {
    id: number;
    menu_item_id: number;
    title: string;
    description: string;
    select_values: string;
    reason_values: string;
    closing_values: string;
    date_required: boolean;
    image_url: string;
    image_name: string;
    hotel_id: number;

    constructor() {
        this.id = 0;
        this.menu_item_id = 0;
        this.title = '';
        this.description = '';
        this.select_values = '';
        this.reason_values = '';
        this.closing_values = '';
        this.date_required = false;
        this.image_url = '';
        this.image_name = '';
        this.hotel_id = 0;
    }
}

export class PDFLink {
    id: number;
    table_name: string;
    type: string;
    table_record_id: number;
    attachment_url: string;
    priority: number;
    name: string;
    description: string;
}

export class Tag {
    id: number;
    table_name: string;
    table_record_id: number;
    name: string;
}