import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ApiDataModel, SearchResult} from '../models/api-data-model';

@Injectable({
  providedIn: 'root'
})
export class RapidApiService {

  apiPath = 'https://public.icibot.net/api/content_api/';
  detailApi = 'https://public.icibot.net/api/content_api_detail';

  constructor(private http: HttpClient) {
  }

  getHotels(query: string): Promise<SearchResult[]> {
    return new Promise((resolve, reject) => {
      this.http.get(this.apiPath + query)
        .subscribe((resp: SearchResult[]) => {
          resolve(resp);
        }, (err) => {
          reject(err);
        });
    });
  }

  getHotelInfo(id: string, longitude: string, latitude: string): Promise<ApiDataModel> {
    const PATH = this.detailApi;

    return new Promise((resolve, reject) => {
      this.http.post(PATH, {
          locale: 'en_US',
          destinationId: id,
          longitude,
          latitude
        }
      )
        .subscribe((resp: ApiDataModel) => {
          resolve(resp);
        }, (err) => {
          reject(err);
        });
    });
  }
}
