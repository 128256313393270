<div class="container">
  <div id="toast" *ngIf="toast" class="bg-danger">
    <fa-icon [icon]="cross"></fa-icon>
    {{ "just3" | translatelocal }}
  </div>

  <div class="right-svg"></div>
  <div class="left-svg"></div>

  <div class="row header">
    <div class="col-12">
      <div class="row">
        <div class="col-12 logo-col">
          <img src="assets/images/logo.svg" alt="Guest Service" class="logo" />
        </div>
      </div>

      <div class="row mt-2">
        <div class="progress">
          <div class="row">
            <div class="col-12">
              <div
                class="progress-bar"
                role="progressbar"
                aria-valuenow="0"
                aria-valuemin="0"
                aria-valuemax="100"
                [style.width]="progressValues[currentPage] + '%'"
              ></div>
            </div>
          </div>
        </div>

        <div class="circles">
          <div class="row">
            <div class="col">
              <div class="circle active">1</div>
              <p class="text text-center text-primary">Select Images</p>
            </div>

            <div class="col">
              <div [class]="pages[1] ? 'circle active' : 'circle'">2</div>
              <p class="text text-center text-primary">Edit Rooms</p>
            </div>

            <div class="col">
              <div [class]="pages[2] ? 'circle active' : 'circle'">3</div>
              <p class="text text-primary">Facilities</p>
            </div>

            <div class="col">
              <div [class]="pages[3] ? 'circle active' : 'circle'">4</div>
              <p class="text text-primary">Services</p>
            </div>

            <div class="col">
              <div [class]="pages[4] ? 'circle active' : 'circle'">5</div>
              <p class="text text-primary">Leisure</p>
            </div>

            <div class="col">
              <div [class]="pages[5] ? 'circle active' : 'circle'">
                <fa-icon [icon]="checkTop"></fa-icon>
              </div>
              <p class="text text-primary">Ready!</p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-4 m-0 p-0 buttons-col">
          <button
            class="btn btn-outline-primary nextbtn back"
            (click)="backPage()"
            *ngIf="pages[1] && currentPage !== 5"
          >
            <fa-icon [icon]="back"> </fa-icon>
            {{ "back" | translatelocal }}
          </button>
        </div>
        <div class="col-md-5 col-sm-12 m-0 p-0 mb-2 text-col">
          <h4 class="text-center">
            <b [innerHtml]="texts[currentPage] | translatelocal"></b>
          </h4>
        </div>
        <div class="col-3 m-0 p-0 buttons-col next-button-col">
          <ng-container *ngIf="currentPage !== 5">
            <button
              class="btn btn-outline-success nextbtn"
              (click)="nextPage()"
              *ngIf="currentPage !== 4"
            >
              <span>{{ "next" | translatelocal }}</span>
              <fa-icon [icon]="next"> </fa-icon>
            </button>
            <button
              class="btn btn-outline-success nextbtn"
              *ngIf="currentPage === 4 && !loading"
              (click)="finish()"
            >
              <span>{{ "finish" | translatelocal }}</span>
              <fa-icon [icon]="next"> </fa-icon>
            </button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="row slider-row" id="step-0">
    <ng-container *ngIf="apidata && apidata.images">
      <div
        class="col-lg-4 col-12"
        *ngFor="let img of apidata.images; let i = index"
      >
        <div class="sliders">
          <input
            type="checkbox"
            id="photo-{{ i }}"
            (change)="selectImage(img.url, i, $event.target.checked)"
          />
          <label for="photo-{{ i }}">
            <fa-icon [icon]="check"></fa-icon>
            <img
              [src]="img.url"
              alt="Slider"
              class="slider-select"
              loading="lazy"
            />
          </label>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="row slider-row steps" id="step-1">
    <ng-container *ngIf="apidata && apidata.rooms; else noroom">
      <div class="col-lg-3 col-12" *ngFor="let room of apidata.rooms">
        <div class="card shadow border rooms">
          <img
            [src]="room.images[0].url"
            alt="Room Image"
            *ngIf="room.images && room.images.length > 0"
          />
          <div class="card-body">
            <div class="form-group">
              <label>{{ "roomname" | translatelocal }}</label>
              <input
                type="text"
                [(ngModel)]="room.name"
                class="form-control text-dark"
              />
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-template #noroom>
      <div class="col-12 align-center justify-content-center noroom">
        <fa-icon [icon]="bed"> </fa-icon>
        <h2>
          <b>{{ "noroom" | translatelocal }}</b>
        </h2>
      </div>
    </ng-template>
  </div>

  <div class="row slider-row steps" id="step-2">
    <div
      class="col-lg-auto col-12"
      *ngFor="let facility of facilities; let i = index"
      (click)="openModal(i)"
    >
      <div
        [class]="
          facility.badge > 0
            ? 'card card-active shadow border facilities'
            : 'card shadow border facilities'
        "
      >
        <div class="circle-badge" *ngIf="facility.badge > 0">
          {{ facility.badge }}
        </div>
        <fa-icon [icon]="facility.icon"></fa-icon>
        <h4>{{ facility.name | translate }}</h4>
      </div>
    </div>
  </div>

  <div class="row slider-row steps" id="step-3">
    <ng-container *ngFor="let facility of services; let i = index">
      <div
        class="col-lg col-12"
        (click)="openModal(i, 's')"
        *ngIf="facility.data?.id !== 117; else roomservice"
      >
        <div
          [class]="
            facility.badge > 0
              ? 'card card-active shadow border facilities'
              : 'card shadow border facilities'
          "
        >
          <div class="circle-badge" *ngIf="facility.badge > 0">
            {{ facility.badge }}
          </div>
          <fa-icon [icon]="facility.icon"></fa-icon>
          <h4>{{ facility.name | translate }}</h4>
        </div>
      </div>

      <ng-template #roomservice>
        <div class="col-lg col-12" (click)="selectRoomService(facility, i)">
          <div
            [class]="
              facility.badge > 0
                ? 'card card-active shadow border facilities'
                : 'card shadow border facilities'
            "
          >
            <div class="circle-badge" *ngIf="facility.badge > 0">
              {{ facility.badge }}
            </div>
            <fa-icon [icon]="facility.icon"></fa-icon>
            <h4>{{ facility.name | translate }}</h4>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </div>

  <div class="row slider-row steps" id="step-4">
    <ng-container *ngIf="!loading">
      <ng-container *ngFor="let facility of leisure; let i = index">
        <div class="col-lg-2 col-12" (click)="openModal(i, 'l')">
          <div
            [class]="
              facility.badge > 0
                ? 'card card-active shadow border facilities'
                : 'card shadow border facilities'
            "
          >
            <div class="circle-badge" *ngIf="facility.badge > 0">
              {{ facility.badge }}
            </div>
            <fa-icon [icon]="facility.icon"></fa-icon>
            <h5>{{ facility.name | translate }}</h5>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="loading">
      <div class="card shadow rounded loading">
        <span
          class="spinner-border spinner-border-lg"
          role="status"
          aria-hidden="true"
        >
          <fa-icon [icon]="cog"></fa-icon>
        </span>

        <div>
          <b class="text-primary mt-4">{{ "pleasewait" | translatelocal }}</b>
        </div>
      </div>
    </ng-container>
  </div>

  <div
    class="row slider-row steps justify-content-md-center align-items-center"
    id="step-5"
  >
    <div class="col-lg-6 col-12">
      <div class="card shadow border finish">
        <a
          [href]="hrefPath"
          target="_blank"
          class="d-flex w-100 justify-content-center"
        >
          <img src="/assets/vectors/confirmation.svg" alt="Successfull"
        /></a>
        <h4>
          <b [innerHtml]="texts[currentPage] | translatelocal"></b>
        </h4>
        <a class="btn btn-primary br-100" [href]="hrefPath" target="_blank"
          >{{ "next" | translatelocal }} <fa-icon [icon]="next"></fa-icon
        ></a>
      </div>
    </div>
  </div>
</div>

<!-- FACILITIES MODAL -->
<div class="modal" *ngIf="modalDisplay">
  <div class="modal-backdrop" (click)="modalDisplay = !modalDisplay"></div>
  <div class="modal-wrapper">
    <app-items
      [data]="facilities[currentCat]"
      [controlData]="newrData[currentCat]"
      (newData)="getData($event)"
    >
    </app-items>
    <button
      class="btn btn-primary contiune-btn"
      (click)="modalDisplay = !modalDisplay"
    >
      {{ "next" | translatelocal }}
    </button>
  </div>
</div>

<!-- LEISURE MODAL -->
<div class="modal" *ngIf="lmodalDisplay">
  <div class="modal-backdrop" (click)="lmodalDisplay = !lmodalDisplay"></div>
  <div class="modal-wrapper">
    <app-items
      [data]="leisure[currentCat]"
      [controlData]="newlData[currentCat]"
      (newData)="getlData($event)"
    >
    </app-items>
    <button
      class="btn btn-primary contiune-btn"
      (click)="lmodalDisplay = !lmodalDisplay"
    >
      {{ "next" | translatelocal }}
    </button>
  </div>
</div>

<!-- SERVICES MODAL -->
<div class="modal" *ngIf="smodalDisplay">
  <div class="modal-backdrop" (click)="smodalDisplay = !smodalDisplay"></div>
  <div class="modal-wrapper">
    <app-items
      [data]="services[currentCat]"
      [controlData]="newsData[currentCat]"
      (newData)="getsData($event)"
    >
    </app-items>
    <button
      class="btn btn-primary contiune-btn"
      (click)="smodalDisplay = !smodalDisplay"
    >
      {{ "next" | translatelocal }}
    </button>
  </div>
</div>
