import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { faCog, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { SearchResult } from 'src/app/models/api-data-model';
import { Form } from 'src/app/models/form-data-model';
import { FormModel } from 'src/app/models/form-model';
import { DataService } from 'src/app/services/data.service';
import { RapidApiService } from 'src/app/services/rapid-api.service';

@Component({
  selector: 'app-ai-signup',
  templateUrl: './ai-signup.component.html',
  styleUrls: ['./ai-signup.component.scss'],
})
export class AiSignupComponent implements OnInit {
  loading = false;
  suggestions: SearchResult[] = [];
  query: string = '';
  signupForm: FormGroup;
  formcond: Form = new Form();
  hotelDetail: SearchResult = new SearchResult();
  sendFormData: FormModel = new FormModel();
  searchIcon = faSearch;
  close = faTimes;
  cog = faCog;

  constructor(
    private rapid: RapidApiService,
    private fb: FormBuilder,
    private dataserv: DataService,
    private router: Router
  ) {
    this.signupForm = this.fb.group({
      hotelName: ['', [Validators.required]],
      name: ['', [Validators.required, Validators.minLength(2)]],
      surname: ['', [Validators.required, Validators.minLength(2)]],
      mail: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
    });
  }

  ngOnInit(): void {}

  async getHotels() {
    try {
      this.loading = true;
      this.suggestions = await this.rapid.getHotels(this.query);
      this.loading = false;
    } catch (err) {
      console.warn(err);
    }
  }

  search(value: string) {
    setTimeout(() => {
      this.getHotels();
    }, 500);
  }

  async setHotel(
    name: string,
    id: string,
    longitude: string,
    latitude: string
  ) {
    this.loading = true;

    this.hotelDetail.destinationId = id;
    this.hotelDetail.latitude = latitude;
    this.hotelDetail.longitude = longitude;
    this.hotelDetail.name = name;
    localStorage.setItem('detail', JSON.stringify(this.hotelDetail));
    this.loading = false;
    this.query = name;
    this.suggestions = [];
  }

  changeLang(lang: string) {
    localStorage.setItem('userLang', lang);
    window.location.reload();
  }

  async save() {
    this.formcond = this.signupForm.value;
    this.sendFormData.hotel_name = this.formcond.hotelName;
    this.sendFormData.created_at = new Date();
    this.sendFormData.email = this.formcond.mail;
    this.sendFormData.name = this.formcond.name;
    this.sendFormData.surname = this.formcond.surname;
    this.sendFormData.destination_id = this.hotelDetail.destinationId;
    this.sendFormData.latitude = this.hotelDetail.latitude;
    this.sendFormData.longitude = this.hotelDetail.longitude;
    this.sendFormData.status = 'none';

    try {
      this.dataserv
        .postGuestServiceSignupForm(this.sendFormData)
        .then((response) => {
          localStorage.setItem('hotelUrl', JSON.stringify(response));
        })

        .catch((error) => {
          alert(`- ${error.error}`);
        });
    } catch (error) {
      console.log('err', error);
    }
    localStorage.setItem('verify', 'true');
    this.router.navigateByUrl('ai-signup/photos');
  }
}
