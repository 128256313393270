import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  showHeader: boolean = true;

  title = 'ici-signup';
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const currentRoute = this.activatedRoute.root;
        let route = currentRoute;
        while (route.firstChild) {
          route = route.firstChild;
        }
        this.showHeader =
          route.snapshot.routeConfig?.path === '' ||
          route.snapshot.routeConfig?.path === 'ai-signup';
      });
  }
}
