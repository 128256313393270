<div>
  <div class="row header">
    <div class="col-12">
      <div class="row mb-5">
        <div class="col-12 logo-col">
          <img
            src="../../../../assets/images/logo.svg"
            alt="Guest Service"
            class="logo"
          />
        </div>
      </div>
      <div class="row px-5 d-flex align-items-center">
        <div class="col-md-10 col-sm-12 m-0 p-0 text-center">
          <h4>
            <b>{{ "slide-3" | translatelocal }}</b>
          </h4>
        </div>
        <div class="col-md-2 col-sm-12 m-0 p-0 mb-2">
          <button
            class="btn btn-outline-success nextbtn"
            (click)="sendSelectedImages()"
          >
            <span>{{ "Save" | translatelocal }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="row slider-row">
    <ng-container *ngIf="images.length > 0 && !loading">
      <div
        class="col-lg-4 col-12 d-flex justify-content-md-center"
        *ngFor="let img of images; let i = index"
      >
        <div class="sliders">
          <input
            type="checkbox"
            id="photo-{{ i }}"
            (change)="selectImage(img.url, i, $event.target.checked)"
          />
          <label for="photo-{{ i }}">
            <fa-icon [icon]="check"></fa-icon>
            <img
              [src]="img"
              alt="Slider"
              class="slider-select"
              loading="lazy"
            />
          </label>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="loading">
      <div class="card shadow rounded loading">
        <span
          class="spinner-border spinner-border-lg"
          role="status"
          aria-hidden="true"
        >
          <fa-icon [icon]="cog"></fa-icon>
        </span>

        <div>
          <b class="text-primary mt-4">{{ "pleasewait" | translatelocal }}</b>
        </div>
      </div>
    </ng-container>
  </div>
</div>
