import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppHotelModel } from '../models/app-hotel-model';
import { FormModel } from '../models/form-model';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  richPath: string =
    'https://b1development.s3.eu-central-1.amazonaws.com/icibotV2/3/RichMobileData.json';
  mobilePath: string =
    'https://b1development.s3.eu-central-1.amazonaws.com/icibotV2/3/MobileData.json';
  form: FormModel = new FormModel();
  formId: number = null;

  constructor(private http: HttpClient) {}

  getRichData() {
    return new Promise((resolve, reject) => {
      this.http.get(this.richPath).subscribe(
        (resp) => {
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getMobileData() {
    return new Promise((resolve, reject) => {
      this.http.get(this.mobilePath).subscribe(
        (resp) => {
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  register(registerData: AppHotelModel) {
    let apiURL = 'https://icibot.net/v2/api/signup_guest_service';
    if (sessionStorage.getItem('license')) {
      apiURL += '?l=' + sessionStorage.getItem('license');
      console.log(apiURL);
    }
    var header = {
      headers: new HttpHeaders().set(
        'Authorization',
        `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE5Mjg5MzA5OTksImV4cGQiOiIyMDMxLTAyLTE1VDE3OjA5OjU5LjAwMFoiLCJpYXQiOjE2MTM1NzA5OTksImlkIjozNTh9.304OvIE-IQh5ID4dpPrrt9Xl4CM8VZys5uPedvKCDgo`
      ),
    };

    return new Promise((resolve, reject) => {
      this.http.post(apiURL, registerData, header).subscribe(
        (resp) => {
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  postUserData(data: FormModel): Promise<any> {
    const header = {
      headers: new HttpHeaders().set(
        'Authorization',
        `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE5Mjg5MzA5OTksImV4cGQiOiIyMDMxLTAyLTE1VDE3OjA5OjU5LjAwMFoiLCJpYXQiOjE2MTM1NzA5OTksImlkIjozNTh9.304OvIE-IQh5ID4dpPrrt9Xl4CM8VZys5uPedvKCDgo`
      ),
    };

    // this.http.post('https://icibot.net/v2/api/signup_guest_service_form', data, header).subscribe((resp: any) => {
    //   localStorage.setItem('apiForm', JSON.stringify(resp));
    //   this.formId = resp.id;
    // }, err => {
    //   console.log(err);
    // });

    return new Promise((resolve, reject) => {
      this.http
        .post<any>(
          'https://icibot.net/v2/api/signup_guest_service_form',
          data,
          header
        )
        .subscribe(
          (resp: any) => {
            localStorage.setItem('apiForm', JSON.stringify(resp));
            this.formId = resp.id;
            resolve(resp);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  putUserData(): Promise<any> {
    const header = {
      headers: new HttpHeaders().set(
        'Authorization',
        `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE5Mjg5MzA5OTksImV4cGQiOiIyMDMxLTAyLTE1VDE3OjA5OjU5LjAwMFoiLCJpYXQiOjE2MTM1NzA5OTksImlkIjozNTh9.304OvIE-IQh5ID4dpPrrt9Xl4CM8VZys5uPedvKCDgo`
      ),
    };
    // this.http.put('https://icibot.net/v2/api/signup_guest_service_form/' + this.formId, this.form, header).subscribe(resp => {
    //   console.log(resp);
    // }, err => {
    //   console.log(err);
    // });

    return new Promise((resolve, reject) => {
      this.http
        .put<any>(
          'https://icibot.net/v2/api/signup_guest_service_form/' + this.formId,
          this.form,
          header
        )
        .subscribe(
          (resp) => {
            resolve(resp);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  //sadece fotoğraf seçip kayıt olacağında giriş yaparken kullanılacak api

  postGuestServiceSignupForm(data: FormModel): Promise<any> {
    const header = {
      headers: new HttpHeaders().set(
        'Authorization',
        `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE5Mjg5MzA5OTksImV4cGQiOiIyMDMxLTAyLTE1VDE3OjA5OjU5LjAwMFoiLCJpYXQiOjE2MTM1NzA5OTksImlkIjozNTh9.304OvIE-IQh5ID4dpPrrt9Xl4CM8VZys5uPedvKCDgo`
      ),
    };

    return new Promise((resolve, reject) => {
      this.http
        .post<any>(
          'https://icibot.net/v2/api/guest_service_signup_form',
          data,
          header
        )
        .subscribe(
          (resp: any) => {
            resolve(resp);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  //fotoğrafları seçip post atılacak api

  postGuestServiceSignupSliderPhotos(data: FormModel): Promise<any> {
    const header = {
      headers: new HttpHeaders().set(
        'Authorization',
        `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE5Mjg5MzA5OTksImV4cGQiOiIyMDMxLTAyLTE1VDE3OjA5OjU5LjAwMFoiLCJpYXQiOjE2MTM1NzA5OTksImlkIjozNTh9.304OvIE-IQh5ID4dpPrrt9Xl4CM8VZys5uPedvKCDgo`
      ),
    };

    return new Promise((resolve, reject) => {
      this.http
        .post<any>(
          'https://icibot.net/v2/api/guest_service_signup_slider_photos',
          data,
          header
        )
        .subscribe(
          (resp: any) => {
            resolve(resp);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
}
