import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  
  constructor(private fireAuth: AngularFireAuth) { }

  loginPhone(phone: string, appVerifier: any) {
    console.log('phone', phone);
    
    return this.fireAuth.signInWithPhoneNumber(phone, appVerifier)
  }

  get windowRef() {
    return window;
  }
}
