import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {faSmileBeam} from '@fortawesome/free-regular-svg-icons';
import {
  faBan, faBusAlt, faChalkboard, faCheck, faCheckCircle,
  faChevronLeft, faChevronRight, faChild, faCocktail, faCoffee, faCog,
  faComments, faConciergeBell, faExclamationCircle, faGolfBall, faHammer,
  faLocationArrow, faLuggageCart, faSkating, faSoap, faSpa, faStar, faSwimmer, faTimes, faUtensils
} from '@fortawesome/free-solid-svg-icons';
import {AppHotelModel, MenuCategory, MenuItem} from 'src/app/models/app-hotel-model';
import {RegisterResponseModel} from 'src/app/models/register-response-model';
import {DataService} from 'src/app/services/data.service';
import {ApiDataModel} from '../../models/api-data-model';
import {Form} from '../../models/form-data-model';

@Component({
  selector: 'app-slider-photos',
  templateUrl: './slider-photos.component.html',
  styleUrls: ['./slider-photos.component.scss']
})
export class SliderPhotosComponent implements OnInit {

  signUpData: AppHotelModel = new AppHotelModel();

  back = faChevronLeft;
  next = faChevronRight;
  check = faCheckCircle;
  checkTop = faCheck;
  cross = faTimes;
  bed = faBan;
  cog = faCog;

  texts: string[] = ['slide-3', 'slide-4', 'slide-5', 'slide-6', 'slide-7', 'success'];
  pages: boolean[] = [true, false, false, false, false, false];
  progressValues: number[] = [0, 25, 43, 61, 78, 100];
  currentPage = 0;

  hrefPath = 'https://admin.icibot.com';

  rData = null;

  facilities = [];
  currentCat = 0;
  newrData: any[] = JSON.parse(localStorage.getItem('facilities')) || null;
  services = [];
  newsData: any[] = JSON.parse(localStorage.getItem('services')) || null;
  leisure = [];
  newlData: any[] = JSON.parse(localStorage.getItem('leisure')) || null;

  formdata: Form = JSON.parse(localStorage.getItem('formdata')) || null;
  apidata: ApiDataModel = JSON.parse(localStorage.getItem('detail')) || null;
  images: any[] = [];

  toast = false;
  modalDisplay = false;
  smodalDisplay = false;
  lmodalDisplay = false;
  loading = false;

  constructor(private router: Router, private dataserv: DataService) {
  }

  async ngOnInit(): Promise<void> {
    this.rData = await this.dataserv.getRichData();

    this.facilities = [
      {
        name: this.rData.restaurants?.title,
        icon: faUtensils,
        data: this.rData.restaurants,
        badge: 0
      },
      {
        name: this.rData.cafes?.title,
        icon: faCoffee,
        data: this.rData.cafes,
        badge: 0
      },
      {
        name: this.rData.bars?.title,
        icon: faCocktail,
        data: this.rData.bars,
        badge: 0
      },
      {
        name: this.rData.meeting_rooms?.title,
        icon: faChalkboard,
        data: this.rData.meeting_rooms,
        badge: 0
      },
      {
        name: this.rData.kids_club?.title,
        icon: faChild,
        data: this.rData.kids_club,
        badge: 0
      },
      {
        name: this.rData.golf_club?.title,
        icon: faGolfBall,
        data: this.rData.golf_club,
        badge: 0
      },
      {
        name: this.rData.pools?.title,
        icon: faSwimmer,
        data: this.rData.pools,
        badge: 0
      },
      {
        name: this.rData.spa?.title,
        icon: faSpa,
        data: this.rData.spa,
        badge: 0
      }
    ];

    this.services = [
      {
        name: this.rData.housekeeping?.title,
        icon: faLuggageCart,
        data: this.rData.housekeeping,
        badge: 0
      },
      {
        name: this.rData.amenities?.title,
        icon: faSoap,
        data: this.rData.amenities,
        badge: 0
      },
      {
        name: this.rData.issues?.title,
        icon: faHammer,
        data: this.rData.issues,
        badge: 0
      },
      {
        name: this.rData.complaint?.title,
        icon: faExclamationCircle,
        data: this.rData.complaint,
        badge: 0
      },
      {
        name: this.rData.room_services?.title,
        icon: faConciergeBell,
        data: this.rData.room_services,
        badge: 0
      },
      {
        name: this.rData.front_office?.title,
        icon: faComments,
        data: this.rData.front_office,
        badge: 0
      }
    ];

    this.leisure = [
      {
        name: this.rData.activity?.title,
        icon: faSkating,
        data: this.rData.activity,
        badge: 0
      },
      {
        name: this.rData.daily_animations?.title,
        icon: faStar,
        data: this.rData.daily_animations,
        badge: 0
      },
      {
        name: this.rData.shows?.title,
        icon: faSmileBeam,
        data: this.rData.shows,
        badge: 0
      },
      {
        name: this.rData.po_is?.title,
        icon: faLocationArrow,
        data: this.rData.po_is,
        badge: 0
      },
      {
        name: this.rData.tours?.title,
        icon: faBusAlt,
        data: this.rData.tours,
        badge: 0
      }
    ];

    if (!this.apidata) {
      this.nextPage();
      this.nextPage();
    } else if (!this.apidata.images) {
      this.apidata.images = [];
      this.nextPage();
    }

    this.createNewData();
  }

  createNewData(): any {

    let DATA: any = JSON.stringify(this.rData);
    DATA = JSON.parse(DATA);

    this.newrData = [
      {
        data: DATA.restaurants,
        name: 'restaurants'
      },
      {
        data: DATA.cafes,
        name: 'cafes'
      },
      {
        data: DATA.bars,
        name: 'bars'
      },
      {
        data: DATA.meeting_rooms,
        name: 'meeting_rooms'
      },
      {
        data: DATA.kids_club,
        name: 'kids_club'
      },
      {
        data: DATA.golf_club,
        name: 'golf_club'
      },
      {
        data: DATA.pools,
        name: 'pools'
      },
      {
        data: DATA.spa,
        name: 'spa'
      },
      {
        data: DATA.massage,
        name: 'massage'
      }
    ];

    this.newsData = [
      {
        data: DATA.housekeeping,
        name: 'housekeeping'
      },
      {
        data: DATA.amenities,
        name: 'amenities'
      },
      {
        data: DATA.issues,
        name: 'issues'
      },
      {
        data: DATA.complaint,
        name: 'complaint'
      },
      {
        data: DATA.room_services,
        name: 'room_services'
      },
      {
        data: DATA.front_office,
        name: 'front_office'
      }
    ];

    this.newlData = [
      {
        data: DATA.activity,
        name: 'activity'
      },
      {
        data: DATA.daily_animations,
        name: 'daily_animations'
      },
      {
        data: DATA.shows,
        name: 'shows'
      },
      {
        data: DATA.po_is,
        name: 'po_is'
      },
      {
        data: DATA.tours,
        name: 'tours'
      }
    ];

    this.newrData.forEach(data => {
      if (data.data?.menu_categories) {
        data.data?.menu_categories.forEach(cat => {
          cat.menu_items = [];
        });
      }
    });

    this.newsData.forEach(data => {
      if(data.data?.menu_categories) {
        data.data.menu_categories[0].menu_items[0].menu_item_title_and_descriptions = [];
      }
    });

    this.newlData.forEach(data => {
      if (data.data?.menu_categories) {
        data.data?.menu_categories.forEach(cat => {
          cat.menu_items = [];
        });
      }
    });
  }

  selectImage(img: any, index: number, input: HTMLInputElement): any {
    const box = document.getElementById(`photo-${index}`) as HTMLInputElement;

    if (input) {
      if (this.images.length !== 3) {
        this.images.push(img);
      } else {
        box.checked = false;
        this.toast = true;

        setTimeout(() => {
          this.toast = false;
        }, 1700);
      }
    } else {
      this.images = this.images.filter(image => image !== img);
    }
  }

  nextPage(): any {
    if (this.currentPage === 0) {
      localStorage.setItem('sliders', JSON.stringify(this.images));
    }
    document.getElementById(`step-${this.currentPage}`).classList.remove('leave');
    document.getElementById(`step-${this.currentPage}`).classList.add('leave');

    this.currentPage++;
    this.pages[this.currentPage] = true;
    setTimeout(() => {
      document.getElementById(`step-${this.currentPage - 1}`).style.display = 'none';
      document.getElementById(`step-${this.currentPage}`).style.display = 'flex';
      document.getElementById(`step-${this.currentPage}`).classList.add('enter');
    }, 300);
  }

  backPage(): any {
    this.pages[this.currentPage] = false;

    document.getElementById(`step-${this.currentPage}`).classList.add('leave');
    document.getElementById(`step-${this.currentPage}`).classList.remove('enter');

    setTimeout(() => {
      document.getElementById(`step-${this.currentPage}`).style.display = 'none';
      this.currentPage--;
      document.getElementById(`step-${this.currentPage}`).style.display = 'flex';
      document.getElementById(`step-${this.currentPage}`).classList.add('enter');
    }, 300);

  }

  openModal(i: number, type?: string) {

    this.currentCat = i;

    if (type === 's') {
      this.smodalDisplay = true;
    } else if (type === 'l') {
      this.lmodalDisplay = true;
    } else {
      this.modalDisplay = true;
    }
  }

  getData(event: any) {

    this.facilities[this.currentCat].badge = 0;

    this.newrData[this.currentCat].data?.menu_categories.forEach(element => {
      element.menu_items = [];
    });

    this.facilities[this.currentCat].data?.menu_categories.forEach((cat, index) => {
      cat.menu_items.forEach(item => {
        event.forEach(element => {
          if (element === item.id) {
            this.facilities[this.currentCat].badge++;
            this.newrData[this.currentCat].data
              ?.menu_categories[index]
              .menu_items.push(item);
          }
        });
      });
    });
    this.newrData = [...new Set(this.newrData)];
    localStorage.setItem('facilities', JSON.stringify(this.newrData));
  }

  getlData(event: any) {

    this.leisure[this.currentCat].badge = 0;

    this.newlData[this.currentCat].data?.menu_categories.forEach(element => {
      element.menu_items = [];
    });

    this.leisure[this.currentCat].data?.menu_categories.forEach((cat, index) => {
      cat.menu_items.forEach(item => {
        event.forEach(element => {
          if (element === item.id) {
            this.leisure[this.currentCat].badge++;
            this.newlData[this.currentCat].data
              ?.menu_categories[index]
              .menu_items.push(item);
          }
        });
      });
    });
    this.newlData = [...new Set(this.newlData)];

    localStorage.setItem('leisure', JSON.stringify(this.newlData));
  }

  getsData(event: any) {

    this.services[this.currentCat].badge = 0;

    event = [...new Set(event)];

    this.newsData[this.currentCat].data.menu_categories[0].menu_items[0].menu_item_title_and_descriptions = [];

    this.services[this.currentCat].data.menu_categories[0].menu_items[0].menu_item_title_and_descriptions
      = [...new Set(this.services[this.currentCat].data?.menu_categories[0].menu_items[0].menu_item_title_and_descriptions)];

    this.services[this.currentCat].data?.menu_categories[0].menu_items[0].menu_item_title_and_descriptions.forEach(element => {
      event.forEach(selected => {
        if (selected === element.id) {
          this.services[this.currentCat].badge++;
          this.newsData[this.currentCat].data
            ?.menu_categories[0]
            .menu_items[0].menu_item_title_and_descriptions.push(element);
        }
      });
    });

    this.newsData = [...new Set(this.newsData)];

    localStorage.setItem('services', JSON.stringify(this.newsData));
  }

  selectRoomService(data, index) {
    if (data.badge === 0) {
      data.badge = 1;
      this.newsData[index].data = JSON.parse(localStorage.getItem('services'))[index].data;

    } else {
      data.badge = 0;
      this.newsData[index].data.menu_categories = [];
    }
  }

  async finish() {
    try {
      this.loading = true;

      this.signUpData.name = this.apidata.name;
      this.signUpData.contact_name = this.formdata.name + ' ' + this.formdata.surname;
      this.signUpData.email = this.formdata.mail;
      this.signUpData.password = this.formdata.password;
      this.signUpData.phone = localStorage.getItem('phone');
      this.signUpData.languages = 'en,tr,';
      this.signUpData.hotel_type = 'Resort';
      this.signUpData.product_type = 'icibot';

      try {
        this.signUpData.sub_domain_name = this.formdata.hotelName.toLowerCase().replace(' ', '')
          .replace(' ', '')
          .replace(' ', '')
          .replace(' ', '')
          .replace(' ', '')
          .replace(' ', '')
          .replace(' ', '')
          .replace(/[^\w\s]/gi, '')
          .replace(/[^\w\s]/gi, '')
          .replace(/[^\w\s]/gi, '')
          .replace(/[^\w\s]/gi, '')
          .replace(/[^\w\s]/gi, '')
          .replace(/[^\w\s]/gi, '')
          .replace('Ğ', 'g')
          .replace('Ü', 'u')
          .replace('Ş', 's')
          .replace('I', 'i')
          .replace('İ', 'i')
          .replace('Ö', 'o')
          .replace('Ç', 'c')
          .replace('ğ', 'g')
          .replace('ü', 'u')
          .replace('ş', 's')
          .replace('ı', 'i')
          .replace('ö', 'o')
          .replace('ç', 'c').toLowerCase();
      } catch {
        this.signUpData.sub_domain_name = '';
      }

      if (this.apidata) {
        try {
          this.signUpData.city = this.apidata.city;
          this.signUpData.country = this.apidata.country;
          this.signUpData.address = this.apidata.address;
          this.signUpData.lat_lng = this.apidata.lat_lng;
        } catch {
          this.signUpData.city = '';
          this.signUpData.country = '';
          this.signUpData.address = '';
          this.signUpData.lat_lng = '';
        }
      }

      try {
        for (const key of Object.keys(this.rData)) {
          if (this.rData[`${key}`] && this.rData[`${key}`].id) {
            this.signUpData[`${key}`] = this.rData[`${key}`];
            this.signUpData[`${key}`].menu_categories = [];
            this.signUpData[`${key}`].show = false;
          }
        }
      } catch (e) {
        console.log('Error', e);
      }


      this.newrData.forEach(x => {
        this.signUpData[`${x.name}`] = x.data;
        try {
          if (x.data?.menu_categories[0].menu_items.length === 0) {
            this.signUpData[`${x.name}`].show = false;
            this.signUpData[`${x.name}`].menu_categories = [];
          }
        } catch (error) {

        }
      });

      this.newlData.forEach(x => {
        this.signUpData[`${x.name}`] = x.data;
        try {
          if (x.data?.menu_categories[0].menu_items.length === 0) {
            this.signUpData[`${x.name}`].show = false;
            this.signUpData[`${x.name}`].menu_categories = [];
          }
        } catch (error) {

        }
      });

      this.newsData.forEach(x => {
        this.signUpData[`${x.name}`] = x.data;
        try {
          if (x.data?.menu_categories[0].menu_items[0].menu_item_title_and_descriptions.length === 0 && x.data.id !== 117) {
            this.signUpData[`${x.name}`].show = false;
            this.signUpData[`${x.name}`].menu_categories = [];
          }
        } catch (error) {
          console.log('Error', error);

        }
      });

      this.images.forEach(image => {
        this.signUpData.photos += image + ',';
      });


      if (this.apidata && this.apidata.rooms) {
        this.signUpData.rooms?.menu_categories.push(new MenuCategory());
        this.apidata.rooms.forEach(room => {
          const roomData: MenuItem = new MenuItem();
          try {

            roomData.active = true;
            roomData.name = `{"tr":"${room.name}","en":"${room.name}","de":"","fr":"","ru":"","ar":"","fa":"","es":""}`;

            room.images.forEach(image => {
              roomData.photos += `${image.url},`;
            });

            roomData.description = `{"tr":"${room.description}","en":"${room.description}","de":"","fr":"","ru":"","ar":"","fa":"","es":""}`;

            // if (room.additionalInfo.details.amenities) {
            //   room.additionalInfo.details.amenities.forEach(amnt => {
            //     roomData.menu_item_bullet_lists
            //       .push({title: `{"tr":"${amnt}","en":"${amnt}","de":"","fr":"","ru":"","ar":"","fa":"","es":""}`, id: 0, menu_item_id: 0});
            //   });
            // }
          } catch (error) {

          }
          // try {
          //   roomData.adult_count = room.ratePlans[0].occupancy.maxAdults;
          //   roomData.child_count = room.ratePlans[0].occupancy.maxChildren;
          // } catch (error) {

          // }
          try {
            this.signUpData.rooms?.menu_categories[0].menu_items.push(roomData);
          } catch (error) {
          }
        });
      } else {
        this.signUpData.rooms.menu_categories = [];
      }

      localStorage.setItem('otelData', JSON.stringify(this.signUpData));

      try {
        this.dataserv.form.status = 'Completed';
        this.dataserv.putUserData();
        const resp: RegisterResponseModel = await this.dataserv.register(this.signUpData) as RegisterResponseModel;
        this.hrefPath = `https://admin.icibot.app/auth/login?apphotelid=${resp.app_hotel_id}&apphotels=${resp.app_hotels}&availableforchat=${resp.available_for_chat}&ccemailaddress=${resp.cc_email_address}&departmentid=${resp.department_id}&email=${resp.email}&expire=${resp.expire}&guestvisible=${resp.guest_visible}&id=${resp.id}&jobtitle=${resp.job_title}&languagecode=${resp.language_code}&lastname=${resp.last_name}&mobilephone=${resp.mobile_phone}&name=${resp.name}&role=${resp.role}&sectionid=${resp.section_id}&seealldepartmentsforchat=${resp.see_all_departments_for_chat}&tokenval=${resp.token_val}&userstatus=${resp.user_status}&created=true`;
        window.location.assign(this.hrefPath);
        this.loading = false;
        this.nextPage();
      } catch (err) {
        alert(err.message);
        this.dataserv.form.status = 'Error';
        this.dataserv.putUserData();
      }


    } catch {
      localStorage.setItem('otelData', JSON.stringify(this.signUpData));
      this.loading = false;
      this.dataserv.form.status = 'Error';
      this.dataserv.putUserData();
    }
  }
}
