import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translatelocal',
  pure: false,
})
export class TranslatelocalPipe implements PipeTransform {
  private translations: any = {};
  private currentLang: string;

  constructor(
    private http: HttpClient,
    private translateService: TranslateService
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.loadTranslations();
    });
    this.loadTranslations();
  }

  loadTranslations() {
    const userLang =
      localStorage.getItem('userLang') || navigator.language.substring(0, 2);

    this.http.get(`/assets/translate/${userLang}.json`).subscribe(
      (data) => {
        this.translations = data;
        this.currentLang = userLang;
      },
      (error) => {
        console.error('Error loading translations:', error);
      }
    );
  }

  transform(value: any, args?: any): any {
    if (!value || !this.translations) {
      return value;
    }

    const translation = this.translations[value];
    return translation || value;
  }
}
