export class FormModel {
  id: number;
  name: string;
  surname: string;
  hotel_name: string;
  hotel_id: number;
  email: string;
  phone_number: string;
  status: string;
  status_message: string;
  destination_id: string;
  longitude: string;
  latitude: string;
  locale: string;
  license_type: string;
  sub_domain_name: string;
  images: string;
  created_at: Date;
  updated_at: Date;
}
