import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  languageBoxOpen: boolean = false;
  currentLanguage = `(${
    localStorage.getItem('userLang')?.toUpperCase() || 'tr'
  })`;

  languagesArray: any = [
    { language_code: 'tr', language_name: 'Turkish' },
    { language_code: 'en', language_name: 'English' },
    { language_code: 'es', language_name: 'Spanish' },
    { language_code: 'ru', language_name: 'Russian' },
  ];

  constructor(private translate: TranslateService) {}

  ngOnInit() {
    const lang =
      localStorage.getItem('userLang') == null
        ? 'tr'
        : `${localStorage.getItem('userLang')}`;

    this.changeLanguage(lang);
  }

  changeLanguage(lang: string) {
    localStorage.setItem('userLang', lang);
    this.translate.use(lang);
    this.currentLanguage = `(${
      localStorage.getItem('userLang')?.toUpperCase() || ''
    })`;
  }
}
