import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AiSignupComponent } from './components/ai-signup/ai-signup/ai-signup.component';
import { HotelSliderPhotosComponent } from './components/ai-signup/hotel-slider-photos/hotel-slider-photos.component';
import { SignupComponent } from './components/signup/signup.component';
import { SliderPhotosComponent } from './components/slider-photos/slider-photos.component';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    component: SignupComponent,
  },
  {
    path: 'configuration',
    canActivate: [AuthGuardService],
    component: SliderPhotosComponent,
  },
  {
    path: 'ai-signup',
    component: AiSignupComponent,
  },
  {
    path: 'ai-signup/photos',
    canActivate: [AuthGuardService],
    component: HotelSliderPhotosComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
