import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-items',
  templateUrl: './items-modal.component.html',
  styleUrls: ['./items-modal.component.scss']
})
export class ItemsModalComponent implements OnInit, OnDestroy {

  @Input() data: any;
  @Input() controlData: any;
  @Output() newData = new EventEmitter<any>();

  dataId: number[] = [];
  checked = new Array();
  check = faCheckCircle;

  loading: boolean = false;
  facility: boolean = true;

  constructor() { }

  ngOnInit() {


    if (this.data.data.menu_area_id === 39 && this.data.data.id !== 111) {

      this.facility = false;
      this.data.data.menu_categories[0].menu_items[0].menu_item_title_and_descriptions.forEach((element, index) => {
        this.checked.push(true);
        this.addItem(element.id, true);
      });


      this.controlData.data.menu_categories[0].menu_items[0].menu_item_title_and_descriptions.forEach((element, index) => {
        this.data.data.menu_categories[0].menu_items[0].menu_item_title_and_descriptions.forEach((item, kindex) => {
          if (item.id === element.id) {
            this.checked[kindex] = true;
            this.dataId.push(item.id);
          }
        });
      });

      this.dataId = [...new Set(this.dataId)];
    } else {

      this.data.data.menu_categories.forEach((cat, index) => {
        this.checked.push(new Array());
        cat.menu_items.forEach((item, kindex) => {
          this.checked[index][kindex] = false;
        });
      })

      this.controlData.data.menu_categories.forEach(element => {
        element.menu_items.forEach(item => {
          this.data.data.menu_categories.forEach((cat, kindex) => {
            if (cat.menu_items.includes(item)) {
              cat.menu_items.forEach((items, index) => {
                if (items.id === item.id) {
                  this.checked[kindex][index] = true;
                  this.dataId.push(item.id);
                }
              });
            }
          });

        });
      });
    }


    this.loading = true;
  }

  ngOnDestroy() {
    this.newData.emit(this.dataId);
  }

  addItem(index: number, input: boolean) {

    if (input) {
      this.dataId.push(index);
    } else {
      this.dataId = this.dataId.filter(x => x !== index);
    }

  }

}
