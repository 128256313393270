<div class="container">
  <h1 class="h1 text-center">{{ "slide-2" | translatelocal }}</h1>
  <br />

  <div class="row" *ngIf="!send">
    <div class="form-group input-group row" style="margin: 0">
      <select
        class="form-control col-lg-5 col"
        [(ngModel)]="countrycode"
        (change)="setModel()"
        (click)="desktop = true"
      >
        <option
          *ngFor="let country of allCountries"
          value="+{{ country[2] }}-{{ country[1] }}"
        >
          <span *ngIf="desktop">{{ country[0] }}</span> (+{{ country[2] }})
        </option>
      </select>

      <input
        type="tel"
        [class]="
          isValid
            ? 'form-control col-lg-7 col'
            : 'form-control col-lg-7 col shadow-danger'
        "
        [(ngModel)]="phone"
        [placeholder]="phoneModel"
      />
    </div>

    <div class="form-group input-group row m-0" *ngIf="!isValid">
      <div class="col-12 p-0">
        <div class="toast float-right">
          {{ "invalidphone" | translatelocal }}
        </div>
      </div>
    </div>

    <div class="form-check" style="margin-top: 2vh">
      <input type="checkbox" class="form-check-input" id="kvvk" checked />
      <label class="form-check-label" for="kvvk"
        ><small class="text-muted" style="line-height: 0">
          {{ "kvvk" | translatelocal }}</small
        ></label
      >
    </div>
    <div class="col-12">
      <div id="re-captha"></div>
    </div>

    <button
      class="btn btn-primary btn-block"
      type="submit"
      (click)="sendCode()"
    >
      {{ "send" | translatelocal }}
    </button>
  </div>

  <div class="row enter" *ngIf="send && !mobile">
    <div class="col-lg-3"></div>

    <div class="col-lg-6 col-12">
      <input
        type="number"
        name="code"
        placeholder="• • • • • •"
        class="form-control"
        max="1000000"
        [(ngModel)]="code"
      />
    </div>

    <button
      class="btn btn-primary btn-block"
      type="submit"
      (click)="verifyCode()"
    >
      {{ "verify" | translatelocal }}
    </button>
  </div>

  <div class="row enter" *ngIf="mobile">
    <div class="col-12">
      <img src="/assets/vectors/call.svg" alt="Successfull" class="w-100" />
      <h2><b [innerHtml]="'mobile' | translatelocal"></b></h2>
    </div>
  </div>
</div>

<div id="toast" *ngIf="toast" class="bg-danger">
  <fa-icon [icon]="cross"></fa-icon>
  <span id="toast-msg">{{ errmsg }}</span>
</div>
