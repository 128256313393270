<div class="container">
  <div class="row my-3 text-center justify-content-center">
    <div class="col-12">
      <h4 class="text-center">
        <b style="width: 100%">{{ data.data.title | translate }} </b><br />
      </h4>
    </div>
    <div class="col-12">
      <span class="text-muted" style="font-weight: 400">
        {{ "selectdefault" | translatelocal }}
      </span>
    </div>
  </div>
  <div class="row" *ngIf="facility; else services">
    <ng-container *ngFor="let cat of data.data.menu_categories; let k = index">
      <ng-container *ngFor="let item of cat.menu_items; let i = index">
        <div class="col-xl-3 col-lg-4">
          <div class="card border shadow" *ngIf="loading">
            <input
              type="checkbox"
              id="check-{{ k }}{{ i }}"
              [checked]="checked[k][i]"
              (change)="addItem(item.id, $event.target.checked)"
            />
            <label for="check-{{ k }}{{ i }}">
              <fa-icon [icon]="check"></fa-icon>
              <img [src]="item.image_url" alt="Item Image" />
              <div class="card-body">
                <h6>
                  <b>{{ item.name | translate }}</b>
                </h6>
              </div>
            </label>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <ng-template #services>
    <div class="row">
      <ng-container
        *ngFor="let cat of data.data.menu_categories; let k = index"
      >
        <ng-container
          *ngFor="
            let item of cat.menu_items[0].menu_item_title_and_descriptions;
            let i = index
          "
        >
          <div class="col-xl-3 col-lg-3 col-md-4">
            <div class="card border shadow" *ngIf="loading">
              <input
                type="checkbox"
                id="check-{{ k }}{{ i }}"
                [checked]="checked[i]"
                (change)="addItem(item.id, $event.target.checked)"
              />
              <label for="check-{{ k }}{{ i }}">
                <fa-icon [icon]="check"></fa-icon>
                <img
                  [src]="item.image_url"
                  alt="Item Image"
                  style="object-fit: contain"
                />
                <div class="card-body py-0">
                  <h6>
                    <b>{{ item.title | translate }}</b>
                  </h6>
                </div>
              </label>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-template>
</div>
