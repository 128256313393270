import {Form} from './form-data-model';

export class SearchResult {
  name: string;
  destinationId: string;
  longitude: string;
  latitude: string;
  locale: string;
}

export class ApiDataModel {
  name: string;
  images: Image[];
  rooms: Room[];
  address: string;
  post_code: string;
  city: string;
  country: string;
  place_id: string;
  lat_lng: string;
}

export class Room {
  name: string;
  description: string;
  images: Image[];
}

export class Image {
  url: string;
}

// // export class ApiDataModel {
// //   pdpHeader: PDPHeader;
// //   overview: Overview;
// //   hotelWelcomeRewards: HotelWelcomeRewards;
// //   propertyDescription: PropertyDescription;
// //   guestReviews: GuestReviews;
// //   atAGlance: AtAGlance;
// //   amenities: Amenity[];
// //   hygieneAndCleanliness: HygieneAndCleanliness;
// //   smallPrint: SmallPrint;
// //   specialFeatures: SpecialFeatures;
// //   miscellaneous: Miscellaneous;
// //   pageInfo: PageInfo;
// //   hotelBadge: HotelBadge;
// //   unavailable: {};
// //   roomsAndRates: RoomsAndRates;
// // }
//
// export class Amenity {
//   heading: string;
//   listItems: ListItem[];
// }
//
// export class ListItem {
//   heading: string;
//   listItems: string[];
// }
//
// export class AtAGlance {
//   keyFacts: KeyFacts;
//   travellingOrInternet: TravellingOrInternet;
//   transportAndOther: TransportAndOther;
// }
//
// export class KeyFacts {
//   hotelSize: string[];
//   arrivingLeaving: string[];
//   specialCheckInInstructions: string[];
//   requiredAtCheckIn: string[];
// }
//
// export class TransportAndOther {
//   transport: Transport;
//   otherInformation: string[];
//   otherInclusions: any[];
// }
//
// export class Transport {
//   transfers: any[];
//   parking: string[];
//   offsiteTransfer: string[];
// }
//
// export class TravellingOrInternet {
//   travelling: Travelling;
//   internet: string[];
// }
//
// export class Travelling {
//   children: string[];
//   pets: string[];
//   extraPeople: any[];
// }
//
// export class GuestReviews {
//   brands: Brands;
// }
//
// export class Brands {
//   scale: number;
//   formattedScale: string;
//   rating: number;
//   formattedRating: string;
//   lowRating: boolean;
//   badgeText: string;
//   total: number;
// }
//
// export class HotelBadge {
//   type: string;
//   label: string;
//   tooltipTitle: string;
//   tooltipText: string;
// }
//
// export class HotelWelcomeRewards {
//   applies: boolean;
//   info: string;
// }
//
// export class HygieneAndCleanliness {
//   title: string;
//   healthAndSafetyMeasures: HealthAndSafetyMeasures;
// }
//
// export class HealthAndSafetyMeasures {
//   title: string;
//   description: string;
//   measures: string[];
// }
//
// export class Miscellaneous {
//   pimmsAttributes: string;
//   showLegalInfoForStrikethroughPrices: boolean;
// }
//
// export class Overview {
//   overviewSections: OverviewSection[];
// }
//
// export class OverviewSection {
//   title?: string;
//   type: string;
//   content: string[];
//   contentType: string;
// }
//
// export class PageInfo {
//   pageType: string;
//   errors: any[];
//   errorKeys: string[];
// }
//
// export class PDPHeader {
//   hotelId: string;
//   destinationId: string;
//   pointOfSaleId: string;
//   currencyCode: string;
//   occupancyKey: string;
//   hotelLocation: HotelLocation;
// }
//
// export class HotelLocation {
//   coordinates: Coordinates;
//   resolvedLocation: string;
//   locationName: string;
// }
//
// export class Coordinates {
//   latitude: number;
//   longitude: number;
// }
//
// export class PropertyDescription {
//   clientToken: string;
//   localisedAddress: Address;
//   address: Address;
//   priceMatchEnabled: boolean;
//   name: string;
//   starRatingTitle: string;
//   starRating: number;
//   mapWidget: MapWidget;
//   roomTypeNames: string[];
//   tagline: string[];
//   freebies: string[];
// }
//
// export class Address {
//   countryName: string;
//   addressLine2: string;
//   cityName: string;
//   postalCode: string;
//   provinceName: string;
//   addressLine1: string;
//   countryCode: string;
//   pattern: string;
//   fullAddress: string;
// }
//
// export class MapWidget {
//   staticMapUrl: string;
// }
//
// export class SmallPrint {
//   alternativeNames: string[];
//   mandatoryFees: any[];
//   optionalExtras: string[];
//   policies: string[];
//   mandatoryTaxesOrFees: boolean;
//   display: boolean;
// }
//
// export class SpecialFeatures {
//   sections: Section[];
// }
//
// export class Section {
//   heading: string;
//   freeText: string;
//   listItems: any[];
//   subsections: any[];
// }
//
//
// export class RoomsAndRates {
//   bookingUrl: string;
//   rooms: Room[];
//   ratePlanWithOffersExists: boolean;
//   priceColumnHeading: string;
// }
//
// export class Room {
//   name: string;
//   images: Image[];
//   bedChoices: BedChoices;
//   additionalInfo: AdditionalInfo;
//   ratePlans: RatePlan[];
// }
//
// export class AdditionalInfo {
//   description: string;
//   details: Details;
// }
//
// export class Details {
//   amenities: string[];
// }
//
// export class BedChoices {
//   mainOptions: string[];
//   extraBedTypes: any[];
// }
//
// export class Image {
//   caption: string;
//   thumbnailUrl: string;
//   fullSizeUrl: string;
// }
//
// export class RatePlan {
//   occupancy: Occupancy;
//   cancellation: Cancellation;
//   cancellations: Cancellation[];
//   features: Feature[];
//   welcomeRewards: WelcomeRewards;
//   offers: Offers;
//   price: Price;
//   urgencyMessage: string;
//   roomsLeft: number;
//   payment: Payment;
// }
//
// export class Cancellation {
//   title: string;
//   free: boolean;
//   info: string;
//   refundable: boolean;
//   period?: string;
// }
//
// export class Feature {
//   featureType: string;
//   title: string;
//   info: string;
//   dataSourceInfo: string;
// }
//
// export class Occupancy {
//   maxAdults: number;
//   maxChildren: number;
// }
//
// export class Offers {
//   offer?: string;
//   valueAdds: ValueAdd[];
// }
//
// export class ValueAdd {
//   id: string;
//   info: string;
//   additionalInfo: string;
// }
//
// export class Payment {
//   book: Book;
//   noCCRequired: boolean;
//   paymentMessage?: string;
// }
//
// export class Book {
//   caption: string;
//   bookingParamsMixedRatePlan: BookingParamsMixedRatePlan;
//   paymentPreference?: PaymentPreference;
// }
//
// export class BookingParamsMixedRatePlan {
//   init: boolean;
//   bookingApiVersion: string;
//   numberOfRoomType: string;
//   orderItems: OrderItem[];
//   propertyDetailsDisplayRate: string;
//   currency: string;
//   minPrice: string;
//   marketingChannelCode: string;
//   interstitial: string;
//   priceConfigurationId: string;
// }
//
// export class OrderItem {
//   supplierType: string;
//   rateCode: string;
//   roomTypeCode: string;
//   businessModel: string;
//   ratePlanConfiguration: string;
//   arrivalDate: string;
//   departureDate: string;
//   destinationId: string;
//   hotelCityId: string;
//   hotelId: string;
//   sequenceNumber: string;
//   tripId: string;
//   tspid: number;
// }
//
// export class PaymentPreference {
//   options: Options;
//   roomTracking: RoomTracking;
// }
//
// export class Options {
//   payNow: Pay;
//   payLater: Pay;
// }
//
// export class Pay {
//   displayedPrice: DisplayedPrice;
//   welcomeRewards: string;
//   overrideBookingParams: OverrideBookingParams;
//   payInCurrency?: boolean;
// }
//
// export class DisplayedPrice {
//   displayedPrice: string;
//   priceInfo: string;
//   approximated: boolean;
// }
//
// export class OverrideBookingParams {
//   businessModel: string;
//   displayedCurrentPrice: string;
// }
//
// export class RoomTracking {
//   prop49: string;
//   prop71: string;
//   prop75: string;
// }
//
// export class Price {
//   current: string;
//   unformattedCurrent: number;
//   old?: string;
//   info: string;
//   nightlyPriceBreakdown: NightlyPriceBreakdown;
// }
//
// export class NightlyPriceBreakdown {
//   additionalColumns: AdditionalColumn[];
//   nightlyPrices: any[];
// }
//
// export class AdditionalColumn {
//   heading: string;
//   value: string;
//   oldValue?: string;
// }
//
// export class WelcomeRewards {
//   info: string;
//   collect: boolean;
//   redeem: boolean;
// }
