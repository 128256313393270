import { Component, NgZone, OnInit } from '@angular/core';
import { faCheckCircle, faCog } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { SseService } from 'src/app/services/sse.service';

@Component({
  selector: 'app-hotel-slider-photos',
  templateUrl: './hotel-slider-photos.component.html',
  styleUrls: ['./hotel-slider-photos.component.scss'],
})
export class HotelSliderPhotosComponent implements OnInit {
  images: string[] = [];
  toast = false;
  check = faCheckCircle;
  photoSubscription!: Subscription;
  formId!: number;
  sendImages: string[] = [];
  sendData: any = {};
  loading = false;
  cog = faCog;

  constructor(
    private sseService: SseService,
    private zone: NgZone,
    public dataService: DataService
  ) {}

  ngOnInit() {
    const hotelPhotosString = localStorage.getItem('hotelphotos');
    if (hotelPhotosString) {
      const hotelPhotos = JSON.parse(hotelPhotosString);
      this.images = hotelPhotos.images || [];
      this.formId = hotelPhotos.formId || null;
    } else {
      this.fetchHotelPhotos();
    }
  }

  async fetchHotelPhotos() {
    const detailString = localStorage.getItem('detail');
    const detail = detailString ? JSON.parse(detailString) : null;
    const destination_id = detail ? detail.destinationId : '';

    this.photoSubscription = await this.sseService
      .getHotelPhoto(destination_id)
      .subscribe({
        next: (data) => {
          const response = JSON.parse(data);
          const formId = Number(response.ID);
          const imageString = response.Images;
          const urls = imageString.match(/https:\/\/[^\s]+/g);
          this.zone.run(() => {
            this.formId = formId;
            this.images.push(...urls);
            this.saveToLocalStorage();
          });
        },

        error: (err) => console.error('Error receiving photo:', err),
      });
  }

  saveToLocalStorage() {
    const hotelPhotos = {
      images: this.images,
      formId: this.formId,
    };
    localStorage.setItem('hotelphotos', JSON.stringify(hotelPhotos));
  }

  selectImage(img: any, index: number, input: HTMLInputElement): any {
    const box = document.getElementById(`photo-${index}`) as HTMLInputElement;

    if (input) {
      if (this.sendImages.length !== 3) {
        this.sendImages.push(img);
      } else {
        box.checked = false;
        this.toast = true;

        setTimeout(() => {
          this.toast = false;
        }, 1700);
      }
    } else {
      this.sendImages = this.sendImages.filter(
        (sendImage) => sendImage !== img
      );
    }
    this.sendData.urls = this.sendImages;
    this.sendData.form_id = this.formId;
  }

  // sendSelectedImages() {
  //   this.loading = true;
  //   console.log('loaci', this.loading);
  //   try {
  //     this.dataService.postGuestServiceSignupSliderPhotos(this.sendData);
  //     const checkHotelUrl = () => {
  //       const url = localStorage.getItem('hotelUrl');
  //       if (url) {
  //         console.log('url', url);
  //         const parsedUrl = JSON.parse(url);
  //         console.log('pae', parsedUrl);
  //         window.location.assign(parsedUrl);
  //         localStorage.removeItem('hotelphotos');
  //         localStorage.removeItem('detail');
  //         localStorage.removeItem('hotelurl');
  //         localStorage.setItem('verify', 'false');
  //       } else {
  //         setTimeout(checkHotelUrl, 500);
  //       }
  //     };
  //     checkHotelUrl();
  //   } catch (error) {}

  //   this.loading = false;
  // }

  async sendSelectedImages() {
    this.loading = true;
    console.log('loaci', this.loading);
    try {
      await this.dataService.postGuestServiceSignupSliderPhotos(this.sendData); // await ekleyelim
      const checkHotelUrl = () => {
        const url = localStorage.getItem('hotelUrl');
        if (url) {
          console.log('url', url);
          const parsedUrl = JSON.parse(url);
          console.log('pae', parsedUrl);
          window.location.assign(parsedUrl);
          localStorage.removeItem('hotelphotos');
          localStorage.removeItem('detail');
          localStorage.removeItem('hotelUrl');
          localStorage.setItem('verify', 'false');
          this.loading = false;
        } else {
          setTimeout(checkHotelUrl, 500);
        }
      };
      checkHotelUrl();
    } catch (error) {
      console.error(error);
    }
  }
}
