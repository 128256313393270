import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SignupComponent } from './components/signup/signup.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslatelocalPipe } from './pipes/translatelocal.pipe';
import { TranslatePipe } from './pipes/translate.pipe';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from 'src/environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SliderPhotosComponent } from './components/slider-photos/slider-photos.component';
import { VerifyComponent } from './components/signup/verify/verify.component';
import { ItemsModalComponent } from './components/slider-photos/items-modal/items-modal.component';
import { StripeModule } from 'stripe-angular';
import { HotelSliderPhotosComponent } from './components/ai-signup/hotel-slider-photos/hotel-slider-photos.component';
import { AiSignupComponent } from './components/ai-signup/ai-signup/ai-signup.component';
import { HeaderComponent } from './components/header/header.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/translate/', '.json');
}
@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    TranslatelocalPipe,
    TranslatePipe,
    SliderPhotosComponent,
    VerifyComponent,
    ItemsModalComponent,
    HotelSliderPhotosComponent,
    AiSignupComponent,
    HeaderComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    FormsModule,
    StripeModule.forRoot(
      'pk_test_51ILxSiFKj46EkveXz9MCLBThbrX3hh9plxc9S7bnnKN0gYAeFX3oTsseXaHPeghxA2qx0OTTH1zGY3BaKbp84I0e005W9Msiyp'
    ),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [TranslateModule, FormsModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
