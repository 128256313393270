import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {faSearch, faTimes} from '@fortawesome/free-solid-svg-icons';
import {Form} from 'src/app/models/form-data-model';
import {DataService} from 'src/app/services/data.service';
import {RapidApiService} from '../../services/rapid-api.service';
import {ApiDataModel, SearchResult} from '../../models/api-data-model';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private rapid: RapidApiService,
    private dataserv: DataService,
    private actvrt: ActivatedRoute,
    private router: Router
  ) {
    this.signupForm = this.fb.group({
      hotelName: ['', [Validators.required]],
      name: ['', [Validators.required, Validators.minLength(2)]],
      surname: ['', [Validators.required, Validators.minLength(2)]],
      mail: ['', [Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
    });
  }


  searchIcon = faSearch;
  close = faTimes;

  img = 'assets/images/default.jfif';

  signupForm: FormGroup;
  formcond: Form = new Form();
  suggestions: SearchResult[] = [];
  hotelinf: ApiDataModel = new ApiDataModel();

  next = false;
  loading = false;
  needCharge = false;

  query: string = null;
  userLang = localStorage.getItem('userLang');

  cardOpt = {
    style: {
      base: {
        iconColor: '#0038cb',
        fontWeight: '500',
        fontFamily: 'Open Sans, sans-serif',
        fontSize: '1.1em',
        height: 'fit-content'
      }
    }
  };

  cardCaptureReady = false;
  token = null;

  bank = {
    country: 'US',
    currency: 'usd',
    routing_number: '110000000',
    account_number: '000123456789',
    account_holder_name: 'Jenny Rosen',
    account_holder_type: 'individual',
    metadata: {
      testedUsing: 'stripe-angular',
      author: 'Acker Apple'
    }
  };

  ngOnInit() {
    if(this.actvrt.snapshot.queryParams.l) {
      sessionStorage.setItem('license', this.actvrt.snapshot.queryParams.l);
    }
    
    if (this.actvrt.snapshot.queryParams.type && (this.actvrt.snapshot.queryParams.type === 'advance' || this.actvrt.snapshot.queryParams.type === 'pro')) {
      localStorage.setItem('userType', this.actvrt.snapshot.queryParams.type);
      this.needCharge = true;
    }
  }

  search(value: string) {
    setTimeout(() => {
      if (this.query === value) {
        this.getHotels();
      }
    }, 500);
  }

  async getHotels() {
    try {
      this.loading = true;
      this.suggestions = await this.rapid.getHotels(this.query);
      this.loading = false;
    } catch (err) {
      console.warn(err);
    }
  }

  async setHotel(id: string, longitude: string, latitude: string) {
    try {
      this.loading = true;
      this.hotelinf = await this.rapid.getHotelInfo(id, longitude, latitude);
    } catch (err) {
      console.log(err);
    }

    localStorage.setItem('detail', JSON.stringify(this.hotelinf));

    this.loading = false;

    this.query = this.hotelinf.name;

    this.suggestions = [];

    this.next = true;
  }

  async save() {
    this.formcond = this.signupForm.value;
    localStorage.setItem('formdata', JSON.stringify(this.formcond));

    this.dataserv.form.hotel_name = this.formcond.hotelName;
    this.dataserv.form.created_at = new Date();
    this.dataserv.form.email = this.formcond.mail;
    this.dataserv.form.hotel_name = this.formcond.hotelName;
    this.dataserv.form.name = this.formcond.name;
    this.dataserv.form.surname = this.formcond.surname;
    this.dataserv.form.status = 'none';
    
    await this.dataserv.postUserData(this.dataserv.form);

    localStorage.setItem('verify', 'true');
    this.router.navigateByUrl('/configuration');

    //! This lines removed because SMS Verification is removed


    // document.getElementById('step-1').classList.add('leave');
    // setTimeout(() => {
    //   document.getElementById('step-1').style.display = 'none';
    //   document.getElementById('step-2').style.display = 'block';
    //   document.getElementById('step-2').classList.add('enter');
    // }, 300);
  }

  changeLang(lang: string) {
    localStorage.setItem('userLang', lang);
    window.location.reload();
  }
}
