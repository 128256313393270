// export class FormDataModel {
//   name: string;
//   images: Image[];
//   rooms: Room[];
//   address: string;
//   post_code: string;
//   city: string;
//   country: string;
//   place_id: string;
//   lat_lng: string;
//   form: Form;
// }
//
// export class Room {
//   name: string;
//   description: string;
//   images: Image[];
// }
//
// export class Image {
//   url: string;
// }

export class Form {
  hotelName: string;
  name: string;
  surname: string;
  mail: string;
  password: string;
}
