<div class="row nav-bar d-flex justify-content-between align-items-center">
  <div class="col-6">
    <img
      src="../../../assets/images/logo.svg"
      alt="Guest Service"
      class="logo"
    />
  </div>
  <div class="col-6 mt-3">
    <ul class="navbar-item-list">
      <li
        class="nav-item language-select-box"
        (click)="languageBoxOpen = !languageBoxOpen"
      >
        <div class="lang">
          <i class="fa-solid fa-globe language-button"></i>
          <span class="language-button ml-1"
            >{{ "Language" | translatelocal }}
            {{ currentLanguage.toUpperCase() }}</span
          >
        </div>

        <ul class="language-list" *ngIf="languageBoxOpen">
          <li
            *ngFor="let lang of languagesArray"
            (click)="changeLanguage(lang.language_code)"
            class="language-item"
          >
            {{ lang.language_name | translatelocal }}
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
